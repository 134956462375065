// @ts-ignore
import * as Sentry from "@sentry/react";
import axios from "axios";

import { eraseCookie } from "~/utils/cookie";
import { createCustomError } from "~/utils/error";

export const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;
// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status > 200) {
            console.log("Error in response interceptor");
            console.error(
                `🔗 URL: ${error.config.url} 🚨 Status: ${error.response.status} 📄 Status Text: ${error.response.statusText}`,
                `🚨 request-id:`,
                error.response.headers["x-request-id"],
            );

            // @ts-ignore
            Sentry.captureException(
                createCustomError("API Handling", "🚨 " + " URL: " + error.config.url + " Status: " + error.response.status, error),
                {
                    tags: {
                        request_id: error?.response?.headers["x-request-id"],
                        backend_api_failing: error?.config?.url,
                        label: "backend_api_error",
                        status: error.response.status,
                        statusText: error.response.statusText,
                    },
                },
            );
        }

        const isRootPath = window.location.pathname === "/";
        const isVerifyPath = window.location.pathname === "/verify";
        const isExecuteRequest = error?.config?.url?.includes("execute");
        if (error?.response?.status === 401 && !isRootPath && !isVerifyPath && !isExecuteRequest) {
            // redirect to login
            window.location.href = `/`;
            eraseCookie("isLoggedIn");
            eraseCookie("skipOnboarding");
            eraseCookie("composio_email");
        }

        throw error;
    },
);
