import { cva, type VariantProps } from "class-variance-authority";
import clsx from "clsx";
import * as React from "react";

import { cn } from "~/utils/cn";

const inputVariaant = cva(
    "block w-full border border-grey-900 font-[500] border-[1px] border-grey-300 bg-[white] text-black-200 bg-[white] pr-3 text-[13px] leading-none placeholder-grey-500 sm:text-sm focus:border-[#6e1bd8]  focus:outline-none focus:ring-[1px] focus:ring-[#F5EEFF] ",
    {
        variants: {
            variant: {
                default: "",
                disabled: "",
            },
            size: {
                default: "py-[11px] rounded-[12px] text-[14px]",
                40: "py-[7px] rounded-[12px] text-[13px]",
                36: "py-[5px] rounded-[12px] text-[13px]",
                32: "py-[3px] pt-[4px] rounded-[12px] text-[13px]",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    icon?: React.ReactNode;
    onEnterPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    rightIcon?: React.ReactNode;
} & VariantProps<typeof inputVariaant>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, onEnterPress, icon, size, rightIcon, ...props }, ref) => {
    const handleKeyUp = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && onEnterPress) onEnterPress(event);
        },
        [onEnterPress],
    );

    return (
        <div className="relative w-full">
            {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">{icon}</div>}
            <input
                type={type}
                onKeyUp={handleKeyUp}
                className={clsx(
                    cn(
                        inputVariaant({
                            className,
                            size,
                        }),
                    ),
                    {
                        "pl-[40px]": icon,
                        "pl-[12px]": !icon,
                        ...(props.disabled && {
                            "cursor-not-allowed": true,
                        }),
                    },
                )}
                ref={ref}
                disabled={props.disabled}
                {...props}
            />
            {rightIcon && <div className="absolute right-3 top-1/2 -translate-y-1/2">{rightIcon}</div>}
        </div>
    );
});

Input.displayName = "Input";

export { Input };
