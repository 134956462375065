export function createCustomError(name: string, message = "", nativeError: Error | null = null) {
    function BackendError() {
        // @ts-ignore
        this.name = name;
        // @ts-ignore
        this.message = message;
        // @ts-ignore
        this.stack = nativeError ? nativeError.stack : new Error().stack;
    }

    BackendError.prototype = new Error();
    // @ts-ignore

    return new BackendError();
}
