import * as Sentry from "@sentry/nextjs";
import { type z } from "zod";

export const parseZodWithSentry = (schema: z.Schema, data: unknown, url: string) => {
    try {
        return schema.parse(data);
    } catch (e) {
        console.error(`Error parsing ${url}`, e);
        console.error(data);
        Sentry.captureException(e, {
            tags: {
                url,
                issue: "backend_type_mismatch",
                internal_label: "backend_type_mismatch",
            },
        });

        return data;
    }
};
